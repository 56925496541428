import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Tick from './../assets/images/tick.png';
import Cross from './../assets/images/cross.png';
import errorSound from './../assets/audio/windows-error.mp3';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const formatData = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
        return []; // Return an empty array if data is invalid or empty
    }
    return Object.entries(data[0]).map(([key, value]) => ({
        key: key.toUpperCase().replace(/_/g, ' '),
        value: typeof value === 'string' ? value : JSON.stringify(value) // Ensure value is a string
    }));
};

const PrintMrp = ({ data }) => {
    const navigate = useNavigate();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [corporateCode, setCorporateCode] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fetchedData, setFetchedData] = useState([]); // Renamed variable
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [errorField, setErrorField] = useState('');
    const [isFormValid, setIsFormValid] = useState(true); // Add form validation state
    const [printOption, setPrintOption] = useState(true);
    const [currentMonthYear, setCurrentMonthYear] = useState('');

    useEffect(() => {
        const getCurrentMonthAndYear = () => {
            const date = new Date();
            const options = { month: 'long', year: 'numeric' };
            const formattedDate = date.toLocaleDateString('en-US', options);
            return formattedDate.replace(' ', ' - ');
        };

        setCurrentMonthYear(getCurrentMonthAndYear());
    }, []);

    const [dimensions, setDimensions] = useState({
        width: 341,
        height: 531
    });
    
    const onResize = (event, { size }) => {
        setDimensions({ width: size.width, height: size.height });
    };
    
    
    const corporateCodeRef = useRef(null);
    const tableRef = useRef(null);
    const saveButtonRef = useRef(null);
    const tableRefLabel = useRef(null)
    const radioFullLabelRef = useRef(null);
    const radioOnlyBarcodeRef = useRef(null);
    const radioHalfLabelRef = useRef(null)
    const tableRefHalfLabel = useRef(null);

    useEffect(() => {
        if (corporateCodeRef.current) {
            corporateCodeRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (successMessage) {
            toast.success(successMessage);
        }
    }, [successMessage]);

    const playErrorSound = () => {
        const audio = new Audio(errorSound);
        audio.play();
    };

    const openModal = (message, field) => {
        playErrorSound();
        setModalMessage(message);
        setErrorField(field);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (errorField === 'corporateCode') {
            setCorporateCode('');
            corporateCodeRef.current.focus();
        }
    };

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const handleCorporateCodeChange = async (event) => {
        const code = event.target.value.trim(); // Remove leading/trailing spaces
        
        setCorporateCode(code); // Update state
        setError(''); // Clear errors
        setSuccessMessage(''); // Clear success messages
    
        // Move focus to the first radio button instead of saveButtonRef
        if (radioFullLabelRef.current) {
            radioFullLabelRef.current.focus();
        }
    
        if (!code) {
            return; // Exit if the input is empty
        }
    
        try {
            const response = await fetch(`${API_BASE_URL}/mrp/get-data-by-ids?ids=${code}`);
            if (!response.ok) {
                throw new Error('Data not found');
            }
            const newFetchedData = await response.json();
    
            if (Array.isArray(newFetchedData)) {
                setFetchedData(newFetchedData);
                setSuccessMessage('Data fetched successfully');
            } else {
                throw new Error('Unexpected data format');
            }
        } catch (error) {
            setError(error.message);
            setFetchedData([]); // Clear data on error
            openModal(error.message, 'corporateCode');
        }
    };
    
    
    
    
    
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            const target = event.target;
            if (target.name === 'corporateCode') {
                handleCorporateCodeChange(event);
            }
        }
    };
    


    const refreshAccessToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const accessToken = localStorage.getItem('accessToken');
        if (!refreshToken) {
            throw new Error('No refresh token found');
        }
      
        const response = await fetch(`${API_BASE_URL}/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
        });
      
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error || 'Failed to refresh token');
        }
      
        // Save new tokens to localStorage
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
      
        return data.accessToken;
      };
      
      const handleLogout = () => {
        console.log('Logging out...'); // Debugging log
        localStorage.clear(); // Update the key here
        navigate('/login'); // Redirect to the login page after logout
      };
    
      const openPdf = async () => {
        try {
            // Define the canvas dimensions
            const canvasWidthPx = 335; // Canvas width in pixels
            const canvasHeightPx = 531; // Canvas height in pixels
    
            // Pixel to points conversion
            const pxToPt = (px) => px * 72 / 96;
    
            if (tableRef.current) {
                const table = tableRef.current;
    
                // Function to clean table content by removing special characters
                const cleanTableContent = (element) => {
                    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null, false);
                    let node = walker.nextNode();
                    while (node) {
                        node.nodeValue = node.nodeValue.replace(/[^\u0000-\u007F]/g, " "); // Removes special characters
                        node = walker.nextNode();
                    }
                };
    
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'pt', // Points
                    format: [pxToPt(canvasWidthPx), pxToPt(canvasHeightPx)]
                });
    
                const marginLeftPt = pxToPt(3);
                const marginTopPt = pxToPt(10);
                const contentWidthPt = pxToPt(canvasWidthPx - 6);
                const contentHeightPt = pxToPt(516);
    
                // Clean the table content before rendering to canvas
                cleanTableContent(table);
    
                // Capture and print the table content
                const canvas = await html2canvas(table, {
                    scale: 2, // Higher scale for better quality
                    useCORS: true,
                    backgroundColor: '#ffffff' // Ensures the background is white
                });
    
                const imgData = canvas.toDataURL('image/png');
    
                pdf.addImage(imgData, 'PNG', marginLeftPt, marginTopPt, contentWidthPt, contentHeightPt);
    
                // Create a new window as a popup to display the PDF
                const popupWindow = window.open('', 'popup', 'width=800,height=600,scrollbars=yes');
    
                // Add the close button in the popup
                popupWindow.document.write(`
                    <html>
                        <head>
                            <title>PDF Preview</title>
                        </head>
                        <body style="margin: 0; padding: 0;">
                            <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
                                <iframe src="${URL.createObjectURL(pdf.output('blob'))}" style="width: 100%; height: 100%; border: none;"></iframe>
                            </div>
                            <button id="closeButton" style="position: absolute; bottom: 20px; right: 20px; z-index: 1000;">Close</button>
                        </body>
                    </html>
                `);
    
                // Make sure the document is closed
                popupWindow.document.close();
    
                // Get the iframe and close button
                const iframe = popupWindow.document.querySelector('iframe');
                const closeButton = popupWindow.document.getElementById('closeButton');
    
                // Wait until the popup content is fully loaded before setting the focus
                popupWindow.onload = () => {
                    closeButton.focus(); // Set focus to the close button once the popup content is loaded
                };
    
                // After print, close the popup window
                iframe.contentWindow.onafterprint = function () {
                    popupWindow.close();  // Close the popup window after printing is completed
                };
    
                // Trigger print dialog
                iframe.contentWindow.focus(); // Focus on the iframe content
                iframe.contentWindow.print(); // Open the print dialog
    
                // Add an event listener for the Enter key to close the popup window
                const closePopupOnEnter = (event) => {
                    if (event.key === 'Enter') {
                        // Close the popup window after pressing Enter
                        popupWindow.close();
                        window.removeEventListener('keydown', closePopupOnEnter); // Remove the event listener after closing
                    }
                };
    
                // Attach the event listener to detect "Enter" key press
                window.addEventListener('keydown', closePopupOnEnter);
    
                // Add event listener to the close button to close the popup window when clicked
                closeButton.addEventListener('click', () => {
                    popupWindow.close();
                });
            }
        } catch (error) {
            console.error('Error generating PDF:', error.message);
        }
    };
    
    
    const savePrintDetails = async () => {
        try {
            let token = localStorage.getItem('accessToken');
            if (!token) {
                console.log('No access token found');
                openModal('User not authenticated');
                return;
            }
    
            // Decode the token and check for expiration
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
    
            if (decodedToken.exp < currentTime) {
                // Token expired, attempt to refresh
                console.log('Access token is expired, attempting to refresh...');
                try {
                    token = await refreshAccessToken();
                } catch (error) {
                    openModal('Session expired. Please log in again.');
                    return;
                }
            }
    
            setIsFormValid(false);
    
            const recordsToSave = [];
    
            for (const barcodeItem of fetchedData) {
                if (!barcodeItem) continue;
    
                const corporatecode = barcodeItem.fsn || barcodeItem.asin;
                const brand = barcodeItem.brand || barcodeItem.marketed_by;
                const manufacturedate = barcodeItem.month_and_year_of_manufacture || barcodeItem.date_of_manufacture;
    
                // Log values to verify correctness
                console.log('Item data:', { corporatecode, brand, manufacturedate });
    
                if (!corporatecode || !brand || !manufacturedate) {
                    throw new Error('Missing data fields for saving print details');
                }
    
                recordsToSave.push({
                    corporatecode,
                    brand,
                    manufacturedate,
                    createdDate: new Date().toISOString(),
                });
            }
    
            // Log records to save
            console.log('Records to save:', recordsToSave);
    
            // Save all records in one API call
            const response = await fetchWithToken(`${API_BASE_URL}/mrp/saveprintdetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(recordsToSave),
            });
    
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to save print details');
            }
    
            toast.success('Print details saved successfully');
            setTimeout(() => {
                setCorporateCode('');
                setSuccessMessage('');
                corporateCodeRef.current.focus();
            }, 2000);
        } catch (error) {
            toast.error(error.message || 'Error saving print details');
        } finally {
            setIsFormValid(true);
        }
    };

    const openPdfLabel = async () => {
        try {
            // Increased dimensions by 50%
            const canvasWidthPx = 340.2; // 226.8 * 1.5
            const canvasHeightPx = 141.75; // 94.5 * 1.5
    
            // Pixel to points conversion
            const pxToPt = (px) => px * 72 / 96;
    
            if (tableRefLabel.current) {
                const table = tableRefLabel.current;
    
                // Function to clean table content by removing special characters
                const cleanTableContent = (element) => {
                    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null, false);
                    let node = walker.nextNode();
                    while (node) {
                        node.nodeValue = node.nodeValue.replace(/[^\u0000-\u007F]/g, " "); // Removes special characters
                        node = walker.nextNode();
                    }
                };
    
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'pt', // Points
                    format: [pxToPt(canvasWidthPx), pxToPt(canvasHeightPx)]
                });
    
                const marginLeftPt = pxToPt(10); // Increased margin
                const marginTopPt = pxToPt(10);
                const contentWidthPt = pxToPt(canvasWidthPx - 20);
                const contentHeightPt = pxToPt(canvasHeightPx - 20);
    
                // Clean the table content before rendering to canvas
                cleanTableContent(table);
    
                // Capture the table with a higher scale for better quality
                const canvas = await html2canvas(table, {
                    scale: 4, // Higher scale for better quality
                    useCORS: true,
                    backgroundColor: '#ffffff'
                });
    
                const imgData = canvas.toDataURL('image/png', 1.0); // Maximum quality
    
                pdf.addImage(imgData, 'PNG', marginLeftPt, marginTopPt, contentWidthPt, contentHeightPt);
    
                // Create a new window as a popup to display the PDF
                const popupWindow = window.open('', 'popup', 'width=800,height=600,scrollbars=yes');
    
                // Add the close button in the popup
                popupWindow.document.write(`
                    <html>
                        <head>
                            <title>PDF Preview</title>
                        </head>
                        <body style="margin: 0; padding: 0;">
                            <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
                                <iframe src="${URL.createObjectURL(pdf.output('blob'))}" style="width: 100%; height: 100%; border: none;"></iframe>
                            </div>
                            <button id="closeButton" style="position: absolute; bottom: 20px; right: 20px; z-index: 1000;">Close</button>
                        </body>
                    </html>
                `);
    
                popupWindow.document.close();
    
                // Get the iframe and close button
                const iframe = popupWindow.document.querySelector('iframe');
                const closeButton = popupWindow.document.getElementById('closeButton');
    
                // Set focus on close button once the popup content is loaded
                popupWindow.onload = () => {
                    closeButton.focus();
                };
    
                // Close the popup window after printing
                iframe.contentWindow.onafterprint = function () {
                    popupWindow.close();
                };
    
                // Trigger print dialog
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
    
                // Add event listener to close the popup on "Enter" key press
                const closePopupOnEnter = (event) => {
                    if (event.key === 'Enter') {
                        popupWindow.close();
                        window.removeEventListener('keydown', closePopupOnEnter);
                    }
                };
    
                window.addEventListener('keydown', closePopupOnEnter);
    
                // Add event listener to the close button
                closeButton.addEventListener('click', () => {
                    popupWindow.close();
                });
            }
        } catch (error) {
            console.error('Error generating PDF:', error.message);
        }
    };
    
   
    const openPdfHalfLabel = async () => {
        try {
            const targetDPI = 500; // Increased to 300 DPI for better print quality
            const baseDPI = 96;
            const scaleFactor = targetDPI / baseDPI; // ~3.125
    
            // Define physical dimensions in inches and scale to target DPI
            const physicalWidthInches = 3.85; // Approx 225.8 / 96, adjust as needed
            const physicalHeightInches = 2.35; // Approx 130.5 / 96, adjust as needed
            const canvasWidthPx = physicalWidthInches * targetDPI; // ~705 pixels
            const canvasHeightPx = physicalHeightInches * targetDPI; // ~408 pixels
    
            // Convert pixels to points for jsPDF
            const pxToPt = (px) => (px * 72) / targetDPI;
    
            if (tableRefHalfLabel.current) {
                const table = tableRefHalfLabel.current;
    
                // Clean table content by removing special characters
                const cleanTableContent = (element) => {
                    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT);
                    let node = walker.nextNode();
                    while (node) {
                        node.nodeValue = node.nodeValue.replace(/[^\u0000-\u007F]/g, " ");
                        node = walker.nextNode();
                    }
                };
    
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'pt',
                    format: [pxToPt(canvasWidthPx), pxToPt(canvasHeightPx)]
                });
    
                // Define margins
                const marginLeftPt = pxToPt(16 * scaleFactor);
                const marginTopPt = pxToPt(16 * scaleFactor);
    
                // Clean the table content
                cleanTableContent(table);
    
                // Capture the table with high resolution
                const canvas = await html2canvas(table, {
                    scale: scaleFactor,
                    useCORS: true,
                    backgroundColor: '#ffffff',
                    width: table.offsetWidth, // Ensure full table width
                    height: table.offsetHeight // Ensure full table height
                });
    
                // Convert canvas to high-quality PNG
                const imgData = canvas.toDataURL('image/png', 1.0);
    
                // Add image to PDF using full canvas dimensions
                pdf.addImage(imgData, 'PNG', marginLeftPt, marginTopPt, pxToPt(canvas.width), pxToPt(canvas.height));
    
                // Generate PDF Blob
                const pdfBlob = pdf.output('blob');
                const url = URL.createObjectURL(pdfBlob);
    
                // Open new window to preview the PDF
                const popupWindow = window.open('', 'popup', 'width=800,height=600,scrollbars=yes');
    
                popupWindow.document.write(`
                    <html>
                        <head><title>PDF Preview</title></head>
                        <body style="margin: 0; padding: 0;">
                            <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
                                <iframe id="pdfIframe" src="${url}" style="width: 100%; height: 100%; border: none;"></iframe>
                            </div>
                            <button id="closeButton" style="position: absolute; bottom: 20px; right: 20px;">Close</button>
                        </body>
                    </html>
                `);
    
                popupWindow.document.close();
    
                // Get elements
                const iframe = popupWindow.document.getElementById('pdfIframe');
                const closeButton = popupWindow.document.getElementById('closeButton');
    
                // Focus and print handling
                popupWindow.onload = () => {
                    closeButton.focus();
                };
    
                iframe.onload = () => {
                    iframe.contentWindow.focus();
                    setTimeout(() => {
                        iframe.contentWindow.print();
                    }, 500);
                };
    
                iframe.contentWindow.onafterprint = () => {
                    popupWindow.close();
                };
    
                const closePopupOnEnter = (event) => {
                    if (event.key === 'Enter') {
                        popupWindow.close();
                        window.removeEventListener('keydown', closePopupOnEnter);
                    }
                };
    
                window.addEventListener('keydown', closePopupOnEnter);
                closeButton.addEventListener('click', () => popupWindow.close());
            }
        } catch (error) {
            console.error('Error generating PDF:', error.message);
        }
    };  
    
    

   
    const handlePrintAndSave = async () => {
        // Call to open PDF
        await openPdf();
    
        // Call to save print details
        await savePrintDetails();
    };

    const handlePrintAndSaveOnlyLabel = async () => {
        // Call to open PDF
        await openPdfLabel();
    
        // Call to save print details
        await savePrintDetails();
    };

    const handlePrintAndSaveHalfLabel = async () => {
        // Call to open PDF
        await openPdfHalfLabel();
    
        // Call to save print details
        await savePrintDetails();
    };
    
    
    // Define fetchWithToken function
    const fetchWithToken = async (url, options = {}) => {
        let token = localStorage.getItem('accessToken');
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${token}`,
        };
    
        let response = await fetch(url, options);
    
        if (response.status === 403) {
            token = await refreshAccessToken();
            options.headers['Authorization'] = `Bearer ${token}`;
            response = await fetch(url, options);
        }
    
        return response;
    };
        
    
        
            

    const formattedData = formatData(fetchedData); // Use renamed variable
   
    const parseConsumerComplaints = (text) => {
        const complaints = {
          text: '',
          telNo: '',
          email: ''
        };
      
        // Regex for matching quoted text
        const textMatch = text.match(/"([^"]*)"/);
        if (textMatch) {
          complaints.text = textMatch[1];
        }
      
        // Regex for matching phone number
        const telNoMatch = text.match(/Tel No:\s*([\d-()]+)/);
        if (telNoMatch) {
          complaints.telNo = telNoMatch[1].trim();
        }
      
        // Regex for matching email
        const emailMatch = text.match(/Email:\s*([\w.-]+@[\w.-]+\.\w+)/);
        if (emailMatch) {
          complaints.email = emailMatch[1];
        }
      
        return complaints;
    };

    const filteredData = (Array.isArray(fetchedData) ? fetchedData : []).filter(item =>
        !['ID', 'BARCODE', 'CREATED AT', 'UPDATED AT', 'FSN', 'MADE IN INDIA', 'REVIEW', 'CONSUMER COMPLAINTS CONTACT'].includes(item.key)
    );
    

    const barcodeItem = Array.isArray(fetchedData) && fetchedData.length > 0 ? fetchedData[0] : null; // Use renamed variable

    return (
        <div className='rounded'>
            <div className='row'>
                <div className='col-5 p-4 bg-white'>
                <form autoComplete='off'>
    <label className='pb-2'>Scan ASN/FSN</label>
    <div className='position-relative'>
        <textarea
            className='form-control mb-3'
            name="corporateCode"
            value={corporateCode}
            onChange={(e) => setCorporateCode(e.target.value)}
            onKeyPress={handleKeyPress}
            ref={corporateCodeRef}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {error && <img className='placeit-oninput' src={Cross} alt={error} />}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {successMessage && <img className='placeit-oninput' src={Tick} alt={successMessage} />}
    </div>

    {/* Radio Buttons */}
    <div className="radio-buttons mb-3">
    <label>
        <input
            type="radio"
            name="labelOption"
            value="full"
            className='me-2'
            checked={printOption === 'fullLabel'}
            onChange={() => setPrintOption('fullLabel')}
            ref={radioFullLabelRef} // Attach the ref here
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    saveButtonRef.current.focus(); // Move focus to the save button after selecting
                }
            }}
        />
        Full Label
    </label>

    <label>
        <input
            type="radio"
            name="labelOption"
            value="half"
            className='me-2 ms-4'
            checked={printOption === 'halfLabel'}
            onChange={() => setPrintOption('halfLabel')}
            ref={radioHalfLabelRef} // Attach the ref here
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    saveButtonRef.current.focus(); // Move focus to the save button after selecting
                }
            }}
        />
        Half Label
    </label>
    
    <label>
        <input
            type="radio"
            name="labelOption"
            value="barcode"
            className='ms-4 me-2'
            checked={printOption === 'onlyBarcode'}
            onChange={() => setPrintOption('onlyBarcode')}
            ref={radioOnlyBarcodeRef} // Attach the ref here
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    saveButtonRef.current.focus(); // Move focus to the save button after selecting
                }
            }}
        />
        Barcode
    </label>
</div>


    {/* Button that triggers different functions based on radio selection */}
    <button 
        type="button" 
        onClick={() => {
            switch (printOption) {
              case 'fullLabel':
                handlePrintAndSave();
                break;
              case 'halfLabel':
                handlePrintAndSaveHalfLabel();
                break;
              case 'onlyBarcode':
                handlePrintAndSaveOnlyLabel();
                break;
              default:
                console.warn('Invalid print option selected');
            }
          }}
        ref={saveButtonRef} 
        className='btn btn-primary'
    >
        Print and Save
    </button>
</form>

                </div>
                <div className='col-7 p-4 bg-white'>
                    {/* Display Data in Table Format */}

                    {(fetchedData || []).length > 0 ? (
                        <div className='mt-4 mx-auto slot'style={{backgroundColor: '#cccccc', width: '345px', height: '165px', padding:'2px'}}>
                          
    <table
        ref={tableRefLabel}
        className='printing-table bg-white'  
        style={{
            width: '340.2px',
            height: '141.75px',
            borderColor: '#000000',
            fontSize: '13px',
            wordBreak: 'break-word',
            margin: '10px 3px 5px 3px',
        }}
    >
                          <tbody>
                          

{filteredData.length > 0 && barcodeItem?.review ? (
    <>
       
       
      
      
    </>
) : (
    <tr className='d-none'>
        <td colSpan="2"></td>
    </tr>
)}
                                    <tr>
                                        <td style={{ border: '0px solid',  padding: '.1rem, .15rem' }} colSpan={2}>
                                            {barcodeItem && barcodeItem.barcode ? (
                                                <div style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={`data:image/png;base64,${barcodeItem.barcode}`}
                                                        alt={barcodeItem.asin}
                                                        height={45}
                                                        width="75%"
                                                        style={{margin: '2px', marginBottom: '0'}}
                                                    />
                                                    <div style={{ fontSize: '15px', marginTop: '2px' }}>
                                                        {barcodeItem.fsn || barcodeItem.asin}
                                                    </div>
                                                </div>
                                            ) : (
                                                'No Barcode'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                           
                        </div>
                    ): (

                        <p> No data available</p>
                    )}

{(fetchedData || []).length > 0 ? (
                        <div className='mt-4 mx-auto slot'style={{backgroundColor: '#cccccc', width: '350px', height: '225px', padding:'2px'}}>
                          {/* <ResizableBox
    height={dimensions.height}
    width={dimensions.width}
    onResize={onResize}
> */}
    <table
        ref={tableRefHalfLabel}
        className='printing-table bg-white'  
        style={{
            width: '338.7px',
            height: '195.75px',
            borderColor: '#000000',
            fontSize: '14px',
            wordBreak: 'break-word',
            margin: '10px 3px 5px 3px',
        }}
    >
                          <tbody>
                          {(filteredData || []).length > 0 && !barcodeItem?.review ? (
    <>
        {barcodeItem?.sku && (
            <tr>
                <td style={{ width: '40%', borderLeft: '1.75px solid',  borderTop: '1.75px solid',  color: '#000000' }}>
                SKU
                    </td>
                <td style={{ width: '60%', borderLeft: '1.75px solid',  borderTop: '1.75px solid', borderRight: '1.75px solid', color: '#000000' }}>
                {barcodeItem.sku}
                    </td>
            </tr>
        )}
        {barcodeItem?.asin && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1.75px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1.75px solid' }}>
             ASIN
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1.75px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1.75px solid', borderRight: '1.75px solid' }}>
             {barcodeItem.asin}
                 </td>
         </tr>
        )}
         {barcodeItem?.name_of_the_commodity && barcodeItem?.brand ? (
             <tr style={{height: '65px'}}>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1.75px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1.75px solid' }}>
             NAME OF THE COMMODITY
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1.75px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1.75px solid', borderRight: '1.75px solid' }}>
             {barcodeItem.name_of_the_commodity}
                 </td>
         </tr>
        ):(
            <tr style={{height: '65px'}}>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1.75px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1.75px solid' }}>
             NAME OF THE COMMODITY
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1.75px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1.75px solid', borderRight: '1.75px solid' }}>
             {barcodeItem.name_of_the_commodity}
                 </td>
         </tr>
        )}
      
      
      
    </>
) : (
    <tr className='d-none'>
        <td colSpan="2">No data available</td>
    </tr>
)}

{filteredData.length > 0 && barcodeItem?.review ? (
    <>
        {barcodeItem?.sku && (
            <tr>
                <td style={{ width: '40%', borderLeft: '1.75px solid',  borderTop: '1.75px solid',  color: '#000000', borderBottom: '0px solid', textAlign: 'center', padding: '0.1rem 0.5rem !important',  }}>
                SKU
                    </td>
                <td style={{ width: '60%', borderLeft: '1.75px solid',  borderTop: '1.75px solid', borderRight: '1.75px solid', color: '#000000',  borderBottom: '0px solid', padding: '0.1rem 0.5rem !important' }}>
                {barcodeItem.sku}
                    </td>
            </tr>
        )}
       
        
      
      
    </>
) : (
    <tr className='d-none'>
        <td colSpan="2"></td>
    </tr>
)}
                                    
                                   
                                    <tr>
                                        <td style={{ border: '1.75px solid',  padding: '.1rem, .15rem' }} colSpan={2}>
                                            {barcodeItem && barcodeItem.barcode ? (
                                                <div style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={`data:image/png;base64,${barcodeItem.barcode}`}
                                                        alt={barcodeItem.asin}
                                                        height={45}
                                                        width="75%"
                                                        style={{margin: '2px', marginBottom: '0'}}
                                                    />
                                                    <div style={{ fontSize: '15px', marginTop: '2px' }}>
                                                        {barcodeItem.fsn || barcodeItem.asin}
                                                    </div>
                                                </div>
                                            ) : (
                                                'No Barcode'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* </ResizableBox> */}
                        </div>
                    ): (

                        <p> No data available</p>
                    )}

                    {(fetchedData || []).length > 0 ? (
                        <div className='mt-4 mx-auto slot'style={{backgroundColor: '#cccccc', width: '345px', height: '535px', padding:'2px'}}>
                          {/* <ResizableBox
    height={dimensions.height}
    width={dimensions.width}
    onResize={onResize}
> */}
    <table
        ref={tableRef}
        className='printing-table bg-white'  
        style={{
            width: '335px',
            height: '516px',
            borderColor: '#000000',
            fontSize: '13px',
            wordBreak: 'break-word',
            margin: '10px 3px 5px 3px',
        }}
    >
                          <tbody>
                          {(filteredData || []).length > 0 && !barcodeItem?.review ? (
    <>
        {barcodeItem?.sku && (
            <tr>
                <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000' }}>
                SKU
                    </td>
                <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000' }}>
                {barcodeItem.sku}
                    </td>
            </tr>
        )}
        {barcodeItem?.asin && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1px solid' }}>
             ASIN
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.asin}
                 </td>
         </tr>
        )}
         {barcodeItem?.name_of_the_commodity && barcodeItem?.brand ? (
             <tr style={{height: '65px'}}>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1px solid' }}>
             NAME OF THE COMMODITY
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.name_of_the_commodity}
                 </td>
         </tr>
        ):(
            <tr style={{height: '65px'}}>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1px solid' }}>
             NAME OF THE COMMODITY
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.name_of_the_commodity}
                 </td>
         </tr>
        )}
        {barcodeItem?.net_quantity && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined,  borderTop: '1px solid' }}>
            NET QUANTITY
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', height: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '42px' : undefined, textAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'center' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '1' : undefined, verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'middle' : undefined,  padding: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '0' : undefined,  paddingTop: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '0px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.net_quantity}
                 </td>
         </tr>
        )}
        {barcodeItem?.mrp && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           MRP
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined, fontSize: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '12px' : undefined,   borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.mrp}
                 </td>
         </tr>
        )}
        {barcodeItem?.month_and_year_of_manufacture && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           MONTH AND YEAR OF MANUFACTURE
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', textTransform:'uppercase', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {currentMonthYear}
                 </td>
         </tr>
        )}
        {barcodeItem?.manufactured_packed_and_marketed_by && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           MANUFACTURED, PACKED AND MARKETED BY
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.manufactured_packed_and_marketed_by}
                 </td>
         </tr>
        )}
        {barcodeItem?.manufactured_and_packed_by && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           MANUFACTURED AND PACKED BY
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.manufactured_and_packed_by}
                 </td>
         </tr>
        )}
         {barcodeItem?.marketed_by && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           MARKETED BY
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.marketed_by}
                 </td>
         </tr>
        )}

{barcodeItem?.product_dimensions && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           PRODUCT DIMENSIONS
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.product_dimensions}
                 </td>
         </tr>
        )}
      

      {barcodeItem?.contact_customer_care_executive_at && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           CONTACT CUSTOMER CARE EXECUTIVE AT
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.contact_customer_care_executive_at}
                 </td>
         </tr>
        )}
        {barcodeItem?.unit_sale_price && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           UNIT SALE PRICE
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.unit_sale_price}
                 </td>
         </tr>
        )}
        {barcodeItem?.country_of_origin && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
           COUNTRY OF ORIGIN
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.country_of_origin}
                 </td>
         </tr>
        )}
        {barcodeItem?.brand && (
             <tr>
             <td style={{ width: '40%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid' }}>
          BRAND
                 </td>
             <td style={{ width: '60%', color: '#000000', borderLeft: '1px solid', verticalAlign: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? 'top' : undefined, lineHeight: barcodeItem?.marketed_by?.includes('CLICKTECH')  ? '15px' : undefined,  borderTop: '1px solid', borderRight: '1px solid' }}>
             {barcodeItem.brand}
                 </td>
         </tr>
        )}
      
      
    </>
) : (
    <tr className='d-none'>
        <td colSpan="2">No data available</td>
    </tr>
)}

{filteredData.length > 0 && barcodeItem?.review ? (
    <>
        {barcodeItem?.sku && (
            <tr>
                <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000', borderBottom: '0px solid', textAlign: 'center', padding: '0.1rem 0.5rem !important', fontSize: '11px' }}>
                SKU
                    </td>
                <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', fontSize: '11px', borderBottom: '0px solid', padding: '0.1rem 0.5rem !important' }}>
                {barcodeItem.sku}
                    </td>
            </tr>
        )}
       
        
        {barcodeItem?.marketed_by && (
             <tr>
             <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000', verticalAlign: 'middle', textAlign: 'center' }}>
            Marketed By
                 </td>
             <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', fontSize: '12px', lineHeight: '15.5px' }}>
             {barcodeItem.marketed_by}
                 </td>
         </tr>
        )}
        {barcodeItem?.manufactured_by && (
             <tr>
             <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000', verticalAlign: 'middle', textAlign: 'center' }}>
           Manufactured By
                 </td>
             <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000' }}>
             {barcodeItem.manufactured_by}
                 </td>
         </tr>
        )}
        {barcodeItem?.date_of_manufacture && (
             <tr>
             <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000', padding:'.03rem .1rem', textAlign: 'center', fontSize: '12px' }}>
           Date of Manufacture
                 </td>
             <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', padding:'.03rem .1rem', fontSize: '12px' }}>
             <span style={{paddingLeft: '10px'}}>{currentMonthYear}</span>
                 </td>
         </tr>
        )}
        {barcodeItem?.brand && (
             <tr style={{height: '10px'}}>
             <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000', textAlign: 'center', padding:'.03rem .1rem' }}>
          Brand
                 </td>
             <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', padding:'.03rem .1rem' }}>
             <span style={{paddingLeft: '10px'}}>  {barcodeItem.brand}</span>
                 </td>
         </tr>
        )}
        {barcodeItem?.net_quantity && (
             <tr>
             <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000', textAlign: 'center', padding:'.03rem .1rem' }}>
          Net Quantity
                 </td>
             <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', padding:'.03rem .1rem' }}>
             <span style={{paddingLeft: '10px'}}> {barcodeItem.net_quantity}</span>
                 </td>
         </tr>
        )}
         {barcodeItem?.country_of_origin && (
             <tr>
             <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000', textAlign: 'center', padding:'.03rem .1rem' }}>
           Country of Origin
                 </td>
             <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', padding:'.03rem .1rem' }}>
             <span style={{paddingLeft: '10px'}}>{barcodeItem.country_of_origin}</span>
                 </td>
         </tr>
        )}

{barcodeItem?.mrp && (
             <tr>
             <td style={{ width: '40%', borderLeft: '1px solid',  borderTop: '1px solid',  color: '#000000', textAlign: 'center', padding:'.03rem .1rem' }}>
           MRP
                 </td>
             <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', fontSize: '12px', padding:'.03rem .1rem' }}>
             {barcodeItem.mrp}
                 </td>
         </tr>
        )}
      
      
    </>
) : (
    <tr className='d-none'>
        <td colSpan="2"></td>
    </tr>
)}
                                     {barcodeItem?.consumer_complaints_contact && (
                                        <>
                                            {parseConsumerComplaints(barcodeItem.consumer_complaints_contact).text && (
                                                <tr>
                                                    <td style={{ borderLeft: '1px solid', borderTop: '1px solid', color: '#000000', verticalAlign: 'middle' }} rowSpan={3}>
                                                        "For Consumer Complaints"
                                                    </td>
                                                    <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', fontSize: '10px', lineHeight: '14px' }}>
                                                        "{parseConsumerComplaints(barcodeItem.consumer_complaints_contact).text}"
                                                    </td>
                                                </tr>
                                            )}
                                            {parseConsumerComplaints(barcodeItem.consumer_complaints_contact).telNo || parseConsumerComplaints(barcodeItem.consumer_complaints_contact).email ? (
                                                <>
                                                    {parseConsumerComplaints(barcodeItem.consumer_complaints_contact).telNo && (
                                                        <tr>
                                                            <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', fontSize: '12px', padding:'.07rem .15rem' }}>
                                                            <span style={{paddingLeft: '10px'}}> Tel no: {parseConsumerComplaints(barcodeItem.consumer_complaints_contact).telNo}</span>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {parseConsumerComplaints(barcodeItem.consumer_complaints_contact).email && (
                                                        <tr>
                                                            <td style={{ width: '60%', borderLeft: '1px solid',  borderTop: '1px solid', borderRight: '1px solid', color: '#000000', fontSize: '12px', padding:'.07rem .15rem' }}>
                                                                E-mail: {parseConsumerComplaints(barcodeItem.consumer_complaints_contact).email}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={2}>No additional contact information available</td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                    {barcodeItem && barcodeItem.review && (
                                        <tr>
                                            <td style={{ borderTop: '1px solid', borderLeft:'1px solid', borderRight: '1px solid', padding:'.07rem .15rem' }} colSpan={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                    {barcodeItem.review}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td style={{ border: '1px solid',  padding: '.1rem, .15rem' }} colSpan={2}>
                                            {barcodeItem && barcodeItem.barcode ? (
                                                <div style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={`data:image/png;base64,${barcodeItem.barcode}`}
                                                        alt={barcodeItem.asin}
                                                        height={45}
                                                        width="75%"
                                                        style={{margin: '2px', marginBottom: '0'}}
                                                    />
                                                    <div style={{ fontSize: '15px', marginTop: '0px' }}>
                                                        {barcodeItem.fsn || 'MADE IN INDIA'}
                                                    </div>
                                                </div>
                                            ) : (
                                                'No Barcode'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* </ResizableBox> */}
                        </div>
                    ): (

                        <p> No data available</p>
                    )}
                </div>
            </div>
            <ToastContainer />
            <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  className="modal-ne"
  overlayClassName="modalne-overlay"
>
  <div className="modalne-body">
    <p>{modalMessage}</p>
    
    {/* Show the Logout button if the modal is for authentication error */}
    {modalMessage === 'User not authenticated' ? (
      <button className="btn btn-primary w-100" onClick={handleLogout}>Logout</button>
    ) : (
      <button className="btn btn-primary w-100" onClick={closeModal}>Close</button>
    )}
  </div>
</Modal>
        </div>
    );
};

export default PrintMrp;
